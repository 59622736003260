import './style.scss'
import { Dropdown, Input, message, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { FC, memo, useEffect, useRef, useState } from 'react'
import * as Api from '@/api/home'
import { Ellipsis, Play } from '@/assets/svg'
import VideoModal from '@/components/VideoModal'
import { downlad, eventTracking, Render, urlSource } from '@/libs/util'
import { DeleteOutlined, DownloadOutlined, EditOutlined, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons'

const Index: FC = () => {
  const [editId, setEditId] = useState<any>()
  const editRef = useRef<any>()
  const [preview, setPreview] = useState<any>()
  const [openIndex, setOpenIndex] = useState<any>()
  const [data, setData] = useState<any[]>([])
  const timeRef = useRef<any>()

  useEffect(() => {
    eventTracking('MyCreationsPage')
    getData()
    timeRef.current = setInterval(() => {
      getData()
    }, 5000)
    return () => {
      if (timeRef.current) {
        clearInterval(timeRef.current)
        timeRef.current = null
      }
    }
  }, [])

  useEffect(() => {
    editRef.current = editId
  }, [editId])

  const getData = async () => {
    if (editRef.current) return
    const res = await Api.getCreations()
    setData(res.list || [])
  }

  const previewVideo = (d: any) => {
    eventTracking('CreationsPlay', {
      url: d.url,
      id: d.id
    })
    setPreview({
      url: d.url,
      title: d.title
    })
  }

  const onValueChange = (e: any) => {
    setData(
      data.map((d) => {
        return d.id === editId
          ? {
              ...d,
              title: e.target.value
            }
          : d
      })
    )
  }

  const saveChange = (d: any) => {
    setEditId('')
    if (d.title.trim()) {
      Api.updateCreations(d.id, {
        title: d.title
      })
    } else {
      getData()
    }
  }

  const renameVideo = (id: any, e: any) => {
    setEditId(id)
    e.stopPropagation()
    setOpenIndex(undefined)
  }

  const deleteVideo = async (id: any, e: any) => {
    e.stopPropagation()
    setOpenIndex(undefined)
    await Api.deleteCreations(id)
    getData()
    message.success('删除成功')
  }

  const onCreationVote = async (d: any, vote_status: number) => {
    await Api.creationVote(d.id, {
      vote_status
    })
    getData()
  }

  return (
    <div className="page-common page-video">
      <div className="common-title">
        <div className="text">作品管理</div>
      </div>
      <div className="page-container">
        {data?.map((d, i) => (
          <div className={`list-box ${openIndex === i ? 'hovered' : ''}`} key={i}>
            {
              <Render>
                {function List() {
                  const [percent, setPercent] = useState(-1)
                  const xhrRef = useRef<any>()
                  const downladVideo = (d: any, e: any) => {
                    eventTracking('CreationsDownload', {
                      url: d.url,
                      id: d.id
                    })
                    e.stopPropagation()
                    if (xhrRef.current) {
                      xhrRef.current.abort()
                      setPercent(0)
                    }
                    const extension = d.url.substring(d.url.lastIndexOf('.')).toLowerCase()
                    const xhr: XMLHttpRequest = downlad(d.url, `${d.title}${extension}`)
                    xhr.onprogress = (e) => {
                      const p = Math.floor((e.loaded / e.total) * 100) // 下载进度
                      console.log('进度', p)
                      setPercent(p)
                      if (p === 100) {
                        setTimeout(() => {
                          setPercent(-1)
                        }, 300)
                      }
                    }
                    xhrRef.current = xhr
                  }

                  return (
                    <div className="box-main" onClick={() => d.status === 2 && previewVideo(d)}>
                      <div
                        className="bg"
                        style={{
                          backgroundImage: `url(${urlSource(d.status !== 2 ? d.digital_human_url : d.url, 'video')})`
                        }}
                      ></div>
                      {d.status === 2 && percent === -1 && (
                        <div className="play">
                          <Play />
                        </div>
                      )}

                      <div className="op">
                        {d.status === 2 && (
                          <div className="btn" onClick={(e) => downladVideo(d, e)}>
                            <DownloadOutlined />
                          </div>
                        )}

                        <Dropdown
                          menu={{
                            items: [
                              {
                                key: '1',
                                label: (
                                  <div className="dropdown-list" onClick={(e) => renameVideo(d.id, e)}>
                                    <EditOutlined />
                                    重命名
                                  </div>
                                )
                              },
                              {
                                key: '2',
                                label: (
                                  <div className="dropdown-list" onClick={(e) => deleteVideo(d.id, e)}>
                                    <DeleteOutlined />
                                    删除
                                  </div>
                                )
                              }
                            ]
                          }}
                          placement="bottom"
                          open={openIndex === i}
                          onOpenChange={(open: boolean) => setOpenIndex(open ? i : undefined)}
                        >
                          <div className="btn" onClick={(e) => e.stopPropagation()}>
                            <Ellipsis />
                          </div>
                        </Dropdown>
                      </div>
                      <div className="photo">
                        <img src={urlSource(d.status !== 2 ? d.digital_human_url : d.url, 'video')} />
                      </div>

                      {percent > -1 && (
                        <div className="mask">
                          <div className="pending">
                            <LoadingOutlined />
                            <div>下载中 {percent}%</div>
                          </div>
                        </div>
                      )}

                      {d.status === 2 && d.tip && (
                        <Tooltip placement="bottom" title={d.tip}>
                          <span className="tips">{d.tip && <InfoCircleOutlined />}</span>
                        </Tooltip>
                      )}

                      {d.status !== 2 && (
                        <div className="mask">
                          {d.status === 1 && (
                            <div className="pending">
                              <LoadingOutlined />
                              <div>生成中, 请稍等</div>
                            </div>
                          )}
                          {d.status === 3 && (
                            <Tooltip placement="bottom" title={d.error_message || d.tip || ''}>
                              <span className="status error">
                                生成失败
                                {d.error_message && <InfoCircleOutlined />}
                              </span>
                            </Tooltip>
                          )}
                        </div>
                      )}
                    </div>
                  )
                }}
              </Render>
            }

            <div className="bottom">
              {editId === d.id ? (
                <Input
                  value={d.title}
                  autoFocus
                  onChange={onValueChange}
                  onBlur={saveChange.bind(this, d)}
                  onPressEnter={saveChange.bind(this, d)}
                />
              ) : (
                <div className="box-title">{d.title}</div>
              )}
              <div className="box-time">{dayjs(d.create_time * 1000).format('YYYY-MM-DD HH:mm')}</div>
            </div>
          </div>
        ))}
      </div>

      <VideoModal preview={preview} onCancel={() => setPreview(undefined)} />
    </div>
  )
}

export default memo(Index)
