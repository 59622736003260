import { cmsApi } from '@/api'
import { UserStore } from '@/global-states'
import { Encrypt } from '@/libs/util'

export const getDigitalHumans = () => {
  return cmsApi.get('digital_humans')
}

export const getGlobalDigitalHumans = () => {
  return cmsApi.get('global_digital_humans')
}

export const getDigitalHumansDetail = (id: string | number) => {
  return cmsApi.get(`digital_humans/${id}`)
}

export const addDigitalHumans = (data: any) => {
  return cmsApi.post('digital_humans', data)
}

export const deleteDigitalHumans = (id: string | number) => {
  return cmsApi.delete(`digital_humans/${id}`)
}

export const updateDigitalHumans = (id: string | number, data: any) => {
  return cmsApi.patch(`digital_humans/${id}`, data)
}

export const addCreations = (time: any, data: any) => {
  const { uid } = UserStore
  return cmsApi.post('creations', data, {
    headers: {
      'X-Cache-Key': Encrypt(`${uid}|${time}`)
    }
  })
}

export const getCreations = () => {
  return cmsApi.get('creations')
}

export const updateCreations = (id: string | number, data: any) => {
  return cmsApi.patch(`creations/${id}`, data)
}

export const deleteCreations = (id: string | number) => {
  return cmsApi.delete(`creations/${id}`)
}

export const getShareAudios = () => {
  return cmsApi.get('shared_audios')
}

export const getGlobalTts = () => {
  return cmsApi.get('global_tts_voices')
}

export const getSelfTts = () => {
  return cmsApi.get('tts_voices')
}

export const voiceClone = (data: any) => {
  return cmsApi.post('tts_voices', data)
}

export const deleteVoice = (id: string | number) => {
  return cmsApi.delete(`tts_voices/${id}`)
}

export const updateVoice = (id: string | number, data: any) => {
  return cmsApi.put(`tts_voices/${id}`, data)
}

export const previewTts = (id: string | number, data: any) => {
  return cmsApi.post(`tts_voices/${id}/preview`, data)
}

export const getLanguageList = () => {
  return cmsApi.get('translatable_languages')
}

export const previewTtsDemo = (data: any) => {
  return cmsApi.post('tts_demos/demos', data)
}

export const creationVote = (id: string | number, data: any) => {
  return cmsApi.post(`creations/${id}/vote`, data)
}

// 获取克隆列表
export const getCloneableVoices = () => {
  return cmsApi.get('cloneable_advanced_tts_voices')
}

// 购买声音
export const buyCloneVoices = () => {
  return cmsApi.post('cloneable_advanced_tts_voices')
}

// 开始克隆
export const startCloneVoices = (id: string | number, data: any) => {
  return cmsApi.post(`cloneable_advanced_tts_voices/${id}/demos`, data)
}

// 完成克隆
export const completeCloneVoices = (id: string | number, data: any) => {
  return cmsApi.post(`cloneable_advanced_tts_voices/${id}/clone`, data)
}

// 数字人市场列表-竞拍
export const getAvatarMarkets = () => {
  return cmsApi.get(`psd/auctions`)
}

// 数字人市场列表-一口价
export const getMerchandises = () => {
  return cmsApi.get(`digital_human_merchandises`)
}

// 数字人市场列表-一口价购买
export const buyMerchandises = (id: string | number) => {
  return cmsApi.post(`digital_human_merchandises/${id}/buy`)
}

// 数字人详情
export const getAvatarMarketDetail = (id: string | number) => {
  return cmsApi.get(`psd/auctions/${id}`)
}

// 数字人报价
export const avatarBidding = (id: string | number, data: any) => {
  return cmsApi.post(`psd/auctions/${id}/bidding`, data)
}

export const authRotate = (data: any) => {
  return cmsApi.post(`auth/rotate`, data)
}
