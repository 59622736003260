import './style.scss'
import { Input, message, Modal, Table } from 'antd'
import dayjs from 'dayjs'
import { FC, memo, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as Api from '@/api/account'
import PayModal from '@/components/PayModal'
import PayPointModal from '@/components/PayPointModal'
import PlanModal, { IProductList } from '@/components/PlanModal'
import { IProduct, IUserPackage, UserStore } from '@/global-states'
import { eventTracking, findClosest } from '@/libs/util'

const Setting: FC = () => {
  const [searchParams] = useSearchParams()
  const { phone } = UserStore
  const [type, setType] = useState<'month' | 'year'>('month')
  const [payModalOpen, setPayModalOpen] = useState(false)
  const [planModalOpen, setPlanModalOpen] = useState(false)
  const [payProduct, setPayProduct] = useState<IProduct>()
  const [payPointModalOpen, setPayPointModalOpen] = useState(false)
  const [userPackage, setUserPackage] = useState<IUserPackage>()
  const [productList, setProductList] = useState<IProductList>({
    base: [],
    high: []
  } as IProductList)
  const [allProductList, setAllProductList] = useState<IProduct[]>([])
  const [logs, setLogs] = useState<any[]>([])
  const [baseRate, setBaseRate] = useState(0)
  const [highRate, setHighRate] = useState(0)
  const [baseProduct, setBaseProduct] = useState<IProduct>()
  const [highProduct, setHighProduct] = useState<IProduct>()
  const [convertModalOpen, setConvertModalOpen] = useState(false)
  const [code, setCode] = useState('')

  const vip = useMemo(() => {
    return !!userPackage?.membership_expire_time
  }, [userPackage])

  const vipExpired = useMemo(() => {
    return !!userPackage?.membership_expired
  }, [userPackage])

  const totalPoint = useMemo(() => {
    if (!userPackage) return 0
    return (
      (userPackage.membership_credits || 0) + (userPackage.permanent_credits || 0) + (userPackage.free_credits || 0)
    )
  }, [userPackage])

  useEffect(() => {
    if (searchParams.get('code')) {
      setPlanModalOpen(true)
    }
  }, [searchParams])

  useEffect(() => {
    eventTracking('PersonalCenterPage')
    getUserPackage()
    getProductList()
    getAllCreditLogs()
  }, [])

  useEffect(() => {
    const index = productList.base.findIndex((p) => p.id === baseProduct?.id)
    setBaseRate(Math.floor(index * (100 / (productList.base.length - 1))))
  }, [baseProduct])

  useEffect(() => {
    const index = productList.high.findIndex((p) => p.id === highProduct?.id)
    setHighRate(Math.floor(index * (100 / (productList.high.length - 1))))
  }, [highProduct])

  const slider = useMemo(() => {
    if (productList) {
      return {
        base: productList.base.reduce((pre, next, index) => {
          const number = Math.floor(index * (100 / (productList.base.length - 1)))
          pre[number] = `${(next.unit_credits * next.units) / 10000}w`
          return pre
        }, {} as any),
        high: productList.high.reduce((pre, next, index) => {
          const number = Math.floor(index * (100 / (productList.base.length - 1)))
          pre[number] = `${(next.unit_credits * next.units) / 10000}w`
          return pre
        }, {} as any)
      }
    }
  }, [productList])

  const getUserPackage = async () => {
    const res: IUserPackage = await Api.getUserPackage()
    setUserPackage(res || {})
  }

  const getProductList = async () => {
    const { list }: { list: IProduct[] } = await Api.getProductList()
    const products = {
      base: list.filter((l) => l.level === 10 && l.units === 1),
      high: list.filter((l) => l.level === 20 && l.units === 1)
    }

    setProductList(products)
    setAllProductList(list)
    setBaseProduct(products.base?.[0])
    setHighProduct(products.high?.[0])
  }

  const onTabChange = (type: 'month' | 'year') => {
    const dw = document.documentElement.clientWidth
    let products
    if (type === 'year') {
      products = {
        base: allProductList.filter((l) => l.level === 10 && l.units === 12),
        high: allProductList.filter((l) => l.level === 20 && l.units === 12)
      }
    }

    if (type === 'month') {
      products = {
        base: allProductList.filter((l) => l.level === 10 && l.units === 1),
        high: allProductList.filter((l) => l.level === 20 && l.units === 1)
      }
    }
    if (products) {
      setProductList(products)
      setBaseProduct(products.base?.[0])
      setHighProduct(products.high?.[0])
    }
    setType(type)
  }

  const getAllCreditLogs = async () => {
    const res = await Api.getAllCreditLogs()
    setLogs(res.list || [])
  }

  const onBaseRateChange = (v: number) => {
    const index = findClosest(
      Object.keys(slider?.base || {}).map((o) => +o),
      v
    )
    setBaseProduct(productList.base?.[index])
  }

  const onHighRateChange = (v: number) => {
    const index = findClosest(
      Object.keys(slider?.base || {}).map((o) => +o),
      v
    )
    setHighProduct(productList.high?.[index])
  }

  const useCode = async () => {
    await Api.useCode({ redeem_code: code })
    setConvertModalOpen(false)
    message.success('兑换成功')
    getUserPackage()
  }

  const upgradeTip = (e: any) => {
    e.stopPropagation()
    Modal.warn({
      title: '升级规则',
      content: (
        <div className="declare-list" style={{ marginLeft: -20 }}>
          <ul style={{ paddingLeft: 10 }}>
            <li style={{ padding: '8px 0' }}>升级后功能权益立即生效, 升级后套餐的积分即刻到账;</li>
            <li style={{ padding: '8px 0' }}>旧套餐不做退补差价，已经给到的积分不会失效;</li>
            <li style={{ padding: '8px 0' }}>
              旧套餐剩余权益(会员时间等)自动延后，在升级套餐结束后继续生效。例如: 用户当前是基础单月会员(2030-02-01 ~
              2030-03-01)，该会员将在2030-03-01到期，如果用户在2030-02-15当天选择升级尊享单月会员，尊享单月会员立即生效(2030-02-15
              ~ 2030-03-15)，尊享单月会员在2030-03-15到期后，旧的基础单月会员还有15天权益，将于(2030-03-15 ~
              2030-04-01)继续生效。
            </li>
          </ul>
        </div>
      ),
      okText: '知道了'
    })
  }

  const onSub = () => {
    setPlanModalOpen(true)
  }

  const onPay = (product?: IProduct) => {
    setPayProduct(product)
    setPayModalOpen(true)
  }

  const toPayPoint = () => {
    eventTracking('RechargeCredits')
    setPayPointModalOpen(true)
  }

  const onPaySuccess = () => {
    getUserPackage()
    getAllCreditLogs()
  }

  return (
    <div className="page-setting">
      {userPackage && (
        <>
          <div className="page-setting-user">
            <div className="header">
              <div className="header-user">
                <div className="user">
                  <div className="user-logo">AI</div>

                  <div className="user-info">
                    <div className="phone">
                      <label>{phone}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="header-desc">
                <div className="left">
                  <label className="text">积分数量</label>
                  <label className="num">{totalPoint}</label>
                </div>
              </div>
            </div>

            {
              <div className="table">
                <div className="title">积分收支明细</div>
                <Table
                  pagination={
                    logs?.length > 8
                      ? {
                          pageSize: 8,
                          showSizeChanger: false
                        }
                      : false
                  }
                  columns={[
                    {
                      title: '类型',
                      dataIndex: 'description',
                      width: 300
                    },
                    {
                      title: '积分变动数量',
                      dataIndex: 'credits_delta',
                      render: (num: number) => <div>{num > 0 ? `+${num}` : num}</div>,
                      width: 300
                    },
                    {
                      title: '时间',
                      dataIndex: 'create_time',
                      width: 300,
                      render: (time: number) => <div>{dayjs(time * 1000).format('YYYY-MM-DD HH:mm')}</div>
                    }
                  ]}
                  locale={{
                    emptyText: <div style={{ padding: '40px 0' }}>暂无记录</div>
                  }}
                  dataSource={logs}
                ></Table>
              </div>
            }
          </div>
        </>
      )}

      <PayPointModal
        open={payPointModalOpen}
        onCancel={() => setPayPointModalOpen(false)}
        onSuccess={() =>
          setTimeout(() => {
            getUserPackage()
          }, 300)
        }
      />

      <PayModal
        open={payModalOpen}
        onCancel={() => setPayModalOpen(false)}
        product={payProduct}
        onSuccess={() =>
          setTimeout(() => {
            onPaySuccess()
          }, 300)
        }
      />

      <PlanModal
        open={planModalOpen}
        onCancel={() => setPlanModalOpen(false)}
        onSuccess={() =>
          setTimeout(() => {
            onPaySuccess()
          }, 300)
        }
      />

      <Modal
        okText="立即兑换"
        cancelText="取消"
        title="请输入兑换码"
        onCancel={() => setConvertModalOpen(false)}
        open={convertModalOpen}
        okButtonProps={{ disabled: !code }}
        onOk={useCode}
      >
        <div className="convert-content">
          <Input value={code} onChange={(e) => setCode(e.target.value)} />
        </div>
      </Modal>
    </div>
  )
}

export default memo(Setting)
