import resso from 'resso'
import { getSystemConfig, getUserInfo } from './api/account'

export interface IUserPackage {
  membership_credits: number
  current_membership_credits: number
  current_membership_package_title: string
  membership_expire_time: number
  membership_expired: boolean
  permanent_credits: number
  free_credits: number
  current_membership_level: number
}

export interface IProduct {
  title: string
  duration_type: number
  id: number
  unit_credits: number
  units: number
  total_price: number
  discounted_unit_price: number
  original_unit_price: number
  level: number
  status: number
}

export interface ISystemConfig {
  background_image: string
  display_name: string
  head_logo: string
  logo: string
  name: string
  welcome_line: string
  ico_url: string
  privacy_agreement_url: string
  service_agreement_url: string
  eula_url: string
  keywords: string
  description: string
  promote_image: string
  digital_human_cost: number
  icp_filing_no: string
}

export const UserStore = resso({
  phone: '',
  uid: '',
  config: {} as ISystemConfig,
  getSystemConfig: async () => {
    if (!UserStore.config.name) {
      const res = await getSystemConfig({ domain: location.hostname })
      UserStore.config = res
      document.title = res.display_name
      const icon = document.querySelector('#link-icon') as any
      if (icon) {
        icon.href = res.ico_url
      }
    }
  },
  setUserToken: (d: any) => {
    localStorage.setItem('token', d.token)
    getUserInfo()
  },
  setUserInfo: (d: any) => {
    UserStore.phone = d.phone
    UserStore.uid = d.uid
  },
  getToken: () => {
    return localStorage.getItem('token')
  },
  loginOut: () => {
    localStorage.removeItem('token')
    location.replace('/login')
  }
})
