import './style.scss'
import { Button, Input, message, Modal, notification, Progress, Radio } from 'antd'
import { RcFile } from 'antd/es/upload'
import Dragger from 'antd/es/upload/Dragger'
import axios from 'axios'
import { Buffer } from 'buffer'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as uuid from 'uuid'
import wavConverter from 'wav-converter'
import { cmsApi } from '@/api'
import * as Api from '@/api/account'
import * as homeApi from '@/api/home'
import AudioTag from '@/assets/audio-tag.webp'
import { Play } from '@/assets/svg'
import PayPointModal from '@/components/PayPointModal'
import PlanModal from '@/components/PlanModal'
import VoiceSettingModal from '@/components/VoiceSettingModal'
import { IUserPackage } from '@/global-states'
import { eventTracking, urlSource } from '@/libs/util'
import {
  ArrowLeftOutlined,
  CheckOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  FormOutlined,
  LoadingOutlined,
  MenuOutlined
} from '@ant-design/icons'
import RichText from './components/RichText'

const CreateVideo: FC = () => {
  const navigate = useNavigate()
  const params: any = useParams()
  const [currentStep, setCurrentStep] = useState(0)
  const [audioFile, setAudioFile] = useState<RcFile>(undefined as any)
  const [audioUrl, setAudioUrl] = useState<string>(undefined as any)
  const [audioInfo, setAudioInfo] = useState<any>({})
  const [percent, setPercent] = useState(0)
  const cancelTokenSource = useRef<any>()
  const [globalTts, setGlobalTts] = useState<any[]>([])
  const [selectTts, setSelectTts] = useState<any>() // 已经确认选择的发音
  const [data, setData] = useState<any[]>([])
  const [globalData, setGlobalData] = useState<any[]>([])
  const [avatarId, setAvatarId] = useState<any>(undefined)
  const [model, setModel] = useState('text')
  const [words, setWords] = useState('') // 文本驱动内容
  const [textInputName, setTextInputName] = useState('未命名') // 文本驱动作品名称
  const [previewId, setPreviewId] = useState<any>(undefined)
  const prviewRef = useRef<any>()
  const [loading, setLoading] = useState(false)
  const audioRef = useRef<any>()
  const currentRef = useRef<any>()
  const [planModalOpen, setPlanModalOpen] = useState(false)
  const [payPointModalOpen, setPayPointModalOpen] = useState(false)
  const [selfTts, setSelfTts] = useState<any[]>([])
  const [type, setType] = useState(0)
  const [edit, setEdit] = useState(false)
  const [userPackage, setUserPackage] = useState<IUserPackage>()
  const [settingPreview, setSettingPreview] = useState<any>()

  const showTtsList = useMemo(() => {
    return type === 0 ? globalTts : selfTts
  }, [type, globalTts, selfTts])

  useEffect(() => {
    ;(window as any).Buffer = Buffer
    setTextInputName('未命名')
    setWords('')
    restartUpload()
    getData()
    getGlobalTts()
    getSelfTts()
    getUserPackage()

    return () => {
      prviewRef.current = undefined
      closeAudio()
    }
  }, [])

  useEffect(() => {
    if (params.avatarId) {
      setAvatarId(+params.avatarId)
    }
  }, [params])

  useEffect(() => {
    if (audioFile) {
      setCurrentStep(1)
      setAudioUrl(URL.createObjectURL(audioFile))
    } else {
      setAudioUrl(undefined as any)
    }
  }, [audioFile])

  useEffect(() => {
    prviewRef.current = previewId
  }, [previewId])

  const saveName = () => {
    if (!textInputName?.trim()) {
      setTextInputName('未命名')
    }
    setEdit(false)
  }

  const getUserPackage = async () => {
    const res: IUserPackage = await Api.getUserPackage()
    setUserPackage(res || {})
  }

  const getGlobalTts = async () => {
    const res = await homeApi.getGlobalTts()
    setGlobalTts(res.list || [])
    setSelectTts((res.list || [])[0])

    setTimeout(() => {
      currentRef.current?.scrollIntoView()
    })
  }

  const getSelfTts = async () => {
    const res = await homeApi.getSelfTts()
    setSelfTts((res.list || []).filter((r: any) => r.status === 1))
  }

  const getData = async () => {
    const [humans, globalHumans] = await Promise.all([homeApi.getDigitalHumans(), homeApi.getGlobalDigitalHumans()])
    setData(humans.list || [])
    setGlobalData(globalHumans.list || [])

    if (!params.avatarId) {
      if (humans.list?.length) {
        setAvatarId(humans.list[0]?.id)
      } else {
        setAvatarId(globalHumans.list?.[0]?.id)
      }
    }

    setTimeout(() => {
      currentRef.current?.scrollIntoView()
    })
  }

  const uploadFile = async (file: RcFile) => {
    try {
      const segs = (file.name || '').split(/\./)
      const { upload_url, oss_key, content_type } =
        (await cmsApi.post('upload_url', {
          extension: segs[segs.length - 1],
          media_type: 2
        })) || {}
      if (!upload_url) {
        throw new Error('failed to upload file')
      }

      cancelTokenSource.current = axios.CancelToken.source()
      setPercent(0)
      setCurrentStep(1)

      await cmsApi.upload(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), file, {
        onUploadProgress: (progress) => {
          const percent = Math.round((progress.progress || 0) * 100)
          setPercent(percent)
        },
        headers: {
          'Content-Type': content_type
        },
        cancelToken: cancelTokenSource.current?.token
      })
      setAudioInfo({
        title: segs[0],
        oss_key
      })
      setCurrentStep(2)
    } catch (err: any) {
      if (err?.code !== 'ERR_CANCELED') {
        message.error(err?.message || err)
      }
    }
  }

  const beforeAudioUpload = async (file: RcFile) => {
    return new Promise((resolve) => {
      const type = file.name.split(/\./)?.at(-1)?.toLocaleLowerCase() as string
      if (['wav', 'mp3', 'm4a'].includes(type)) {
        const audioElement = document.createElement('audio')
        audioElement.src = URL.createObjectURL(file)
        audioElement.addEventListener('loadedmetadata', () => {
          console.log('videoElement', audioElement.duration)
          if (audioElement.duration < 5 || audioElement.duration > 1800) {
            message.warning('音频时长要求为5秒～30分钟')
            resolve(false)
          }
          resolve(true)
        })
      } else {
        message.warning('请上传mp3、m4a、wav格式音频文件')
        resolve(false)
      }
    })
  }

  const onDrop = async (file: RcFile) => {
    const type = file.name.split(/\./)?.at(-1)?.toLocaleLowerCase() as string
    if (!['wav', 'mp3', 'm4a'].includes(type)) {
      message.warning('请上传mp4、mov格式视频文件')
    }
  }

  const restartUpload = () => {
    cancelUpload()
    setCurrentStep(0)
    setPercent(0)
    setAudioFile(undefined as any)
    setAudioInfo(undefined as any)
  }

  const cancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.current?.cancel('取消上传')
    }
  }

  const completeCreate = async () => {
    setLoading(true)

    try {
      const res = await homeApi.authRotate({
        seed: uuid.v4(),
        replace: true
      })
      if (model === 'text') {
        eventTracking('CreateConfirm', {
          avatarId,
          voiceName: selectTts.name
        })
        const translateWords = words
          .replace(/<(?!\/?(break|phoneme|category)\b)[^>]+>/g, '')
          .replace(/<break\s+class="[^"]*"\s+time="([^"]*)"\s+time-text="[^"]*"\s*>/g, '<break time="$1">')

        await homeApi.addCreations(res.server_time, {
          type: 0,
          digital_human_id: avatarId,
          title: textInputName,
          text_to_audio: {
            text: translateWords,
            voice_name: selectTts.name
          },
          client_type: 1
        })
      } else {
        eventTracking('CreateConfirm', {
          avatarId,
          ossKey: audioInfo.oss_key
        })
        await homeApi.addCreations(res.server_time, {
          type: 0,
          digital_human_id: avatarId,
          title: textInputName,
          audio_oss_key: audioInfo.oss_key,
          client_type: 1
        })
      }
      notification.success({
        message: '视频生成中',
        description: '新的数字人驱动视频生成中...，可在作品管理中查看！'
      })
      toVideo()
    } catch (error: any) {
      if (error.code === 1002) {
        setPayPointModalOpen(true)
      }
      if (error.code === 1003 || error.code === 1004 || error.code === 1005) {
        setPlanModalOpen(true)
      }
    } finally {
      setLoading(false)
    }
  }

  const submitText = () => {
    const text = words.replace(/<[^>]*>/g, '')

    if (!words.trim()) {
      return message.warning('请输入台词')
    }

    if (text.trim()?.length < 5) {
      return message.warning('台词至少五个字')
    }
    completeCreate()
  }

  const submitAuduio = () => {
    if (!audioInfo?.oss_key) {
      return message.warning('请上传音频')
    }
    completeCreate()
  }

  const onChange = (text: string) => {
    setWords(text)
  }

  const previewWords = async (words: string) => {
    return await previewTts(selectTts, words)
  }

  const previewTts = async (tts: any, text: string) => {
    closeAudio()
    setPreviewId(tts.id)

    try {
      const res = await homeApi.previewTts(tts.id, { text, voice_parameters: tts.voice_parameters })

      if (prviewRef.current === tts.id) {
        const audio = new Audio()
        audio.src = `data:audio/wav;base64,${wavConverter
          .encodeWav(new Buffer(res.audio_base64, 'base64'), {
            numChannels: 1,
            sampleRate: 16000,
            byteRate: 32_000
          })
          .toString('base64')}`
        audio.play()
        audioRef.current = audio
        setPreviewId(undefined)
      }
    } catch (e) {
      return e
    } finally {
      setPreviewId(undefined)
    }
  }

  const closeAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause?.()
      audioRef.current.src = ''
    }
  }

  const toVideo = () => {
    navigate('/video')
  }

  const upgradePlan = () => {
    Modal.confirm({
      title: '当前会员等级不够，无法使用',
      content: <div>声音克隆-高保真是尊享版级以上会员专属功能，请您确认当前会员等级收否匹配</div>,
      okText: '升级会员',
      cancelText: '取消',
      onOk: () => {
        setPlanModalOpen(true)
      }
    })
  }

  return (
    <div className="page-create page-create-video">
      <div className="page-header">
        <div className="title">
          <span className="icon" onClick={() => navigate(-1)}>
            <ArrowLeftOutlined />
          </span>
          <label>创建视频</label>
        </div>
        <div className="right">
          {edit ? (
            <Input
              autoFocus
              maxLength={20}
              value={textInputName}
              onChange={(e) => setTextInputName(e.target.value)}
              onBlur={saveName}
              onPressEnter={saveName}
            ></Input>
          ) : (
            <label className="name">
              {textInputName}
              <FormOutlined onClick={() => setEdit(true)} />
            </label>
          )}
        </div>
      </div>

      <div className="create-content">
        <div className="left">
          <div className="avatar">
            <div className="title">我的数字分身</div>
            <div className="list">
              {data.map((d) => (
                <div className="list-item" key={d.id} onClick={() => setAvatarId(d.id)}>
                  {d.id === avatarId && (
                    <div className="checked">
                      <CheckOutlined />
                    </div>
                  )}

                  <div
                    className="bg"
                    style={{
                      backgroundImage: `url(${urlSource(d.video_url, 'video')})`
                    }}
                  ></div>
                  <img src={urlSource(d.video_url, 'video')} />
                  {d.id === avatarId && <div ref={currentRef}></div>}
                </div>
              ))}
            </div>
          </div>
          <div className="avatar">
            <div className="title">公用数字分身</div>
            <div className="list">
              {globalData.map((d) => (
                <div className="list-item" key={d.id} onClick={() => setAvatarId(d.id)}>
                  {d.id === avatarId && (
                    <div className="checked">
                      <CheckOutlined />
                    </div>
                  )}
                  <div
                    className="bg"
                    style={{
                      backgroundImage: `url(${urlSource(d.video_url, 'video')})`
                    }}
                  ></div>
                  <img src={urlSource(d.video_url, 'video')} />
                  {d.id === avatarId && <div ref={currentRef}></div>}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="right">
          <Radio.Group
            value={model}
            onChange={(e) => {
              setModel(e.target.value)
            }}
            buttonStyle="solid"
          >
            <Radio.Button value="text">文本驱动</Radio.Button>
            <Radio.Button value="audio">音频驱动</Radio.Button>
          </Radio.Group>

          {model === 'text' && (
            <>
              <div className="step-content">
                <div className="center">
                  <div className="header">
                    <label>台词</label>
                  </div>
                  {/* <Input.TextArea
                    className="textarea-words"
                    value={words}
                    placeholder="请输入台词"
                    showCount
                    maxLength={10000}
                    onChange={(e) => setWords(e.target.value)}
                  ></Input.TextArea> */}

                  <RichText onChange={onChange} onPreviewWords={previewWords} />

                  <div className="header">
                    <label>声音</label>
                  </div>

                  <div className="nav">
                    <div className={`nav-item ${type === 0 ? 'actived' : ''}`} onClick={() => setType(0)}>
                      <label>公用</label>
                    </div>
                    <div className={`nav-item ${type === 1 ? 'actived' : ''}`} onClick={() => setType(1)}>
                      <label>我的</label>
                    </div>
                  </div>

                  <div className="voice-list">
                    <div className="list">
                      {showTtsList?.map((t: any) => (
                        <div className="list-item" key={t.id}>
                          {t.id === selectTts?.id && (
                            <div className="checked">
                              <CheckOutlined />
                            </div>
                          )}
                          <Button
                            className={t.id === selectTts?.id ? 'actived' : ''}
                            onClick={() => {
                              if (userPackage && (userPackage.current_membership_level || 0) < 20 && t.level === 20) {
                                return upgradePlan()
                              }
                              setSelectTts(t)
                            }}
                          >
                            {previewId === t.id ? (
                              <LoadingOutlined />
                            ) : (
                              <Play
                                onClick={(e: any) => {
                                  e.stopPropagation()
                                  if (
                                    userPackage &&
                                    (userPackage.current_membership_level || 0) < 20 &&
                                    t.level === 20
                                  ) {
                                    return upgradePlan()
                                  }
                                  setSelectTts(t)
                                  previewTts(t, '现在的一切都是为将来的梦想编织翅膀，让梦想在现实中展翅高飞。')
                                }}
                              />
                            )}
                            <label className="ellipsis name">{t.display_name}</label>
                            {type === 1 && (
                              <MenuOutlined
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setSettingPreview(t)
                                }}
                              />
                            )}

                            {t.level === 20 && <img className="tag" src={AudioTag} />}
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="footer-btn">
                  <Button loading={loading} type="primary" onClick={submitText}>
                    提交
                  </Button>
                </div>
              </div>
            </>
          )}

          {model === 'audio' && (
            <>
              <div className="step-content">
                <div className="center">
                  <div className="warning">
                    <h4>音频要求</h4>
                    <div className="desc">
                      <div>
                        <label className="label">文件格式：</label>
                        <label>mp3、m4a、wav</label>
                      </div>
                      <div>
                        <label className="label">音频时长：</label>
                        <label>5秒～30分钟</label>
                      </div>
                    </div>
                  </div>

                  <div className="content">
                    {currentStep === 0 && (
                      <Dragger
                        accept=".mp3,.m4a,.wav"
                        showUploadList={false}
                        beforeUpload={async (file) => {
                          const flag = (await beforeAudioUpload(file)) as any
                          if (flag) {
                            setAudioFile(file)
                            uploadFile(file)
                          }
                          return flag
                        }}
                        onDrop={(e) => onDrop(e.dataTransfer.files?.[0] as any)}
                      >
                        <p className="ant-upload-drag-icon">
                          <CloudUploadOutlined />
                        </p>
                        <p className="ant-upload-text">上传音频, 驱动生成视频</p>
                        <p className="ant-upload-hint">将文件拖到此处，或点击此区域上传</p>
                      </Dragger>
                    )}

                    {currentStep === 1 && (
                      <div className="step-progress">
                        <div className="step-progress-content">
                          <div className="percent">{percent}%</div>
                          <Progress percent={percent} showInfo={false} />
                          <div className="tips">音频上传中</div>
                        </div>
                        <div className="btns">
                          <Button onClick={restartUpload}>取消</Button>
                        </div>
                      </div>
                    )}

                    {currentStep === 2 && (
                      <div className="step-view">
                        <div className="step-view-box">
                          <audio controls src={audioUrl} />
                          <div className="trash" onClick={restartUpload}>
                            <DeleteOutlined />
                          </div>
                        </div>
                        <div className="btns">
                          <div>重新上传</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="footer-btn">
                  <Button loading={loading} type="primary" onClick={submitAuduio}>
                    提交
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <VoiceSettingModal
        preview={settingPreview}
        onCancel={() => setSettingPreview(undefined as any)}
        onOk={() => {
          setSettingPreview(undefined as any)
          getSelfTts()
        }}
      />

      <PayPointModal
        open={payPointModalOpen}
        onCancel={() => setPayPointModalOpen(false)}
        onSuccess={() => getUserPackage()}
      />

      <PlanModal open={planModalOpen} onCancel={() => setPlanModalOpen(false)} onSuccess={() => getUserPackage()} />
    </div>
  )
}

export default CreateVideo
