import { ConfigProvider, notification } from 'antd'
import { Suspense, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import * as HomeApi from '@/api/home'
import AuthRouter from '@/routers/authRouter'
import Router from '@/routers/index'
import * as Api from './api/account'
import { UserStore } from './global-states'
import { urlSource } from './libs/util'

const App = () => {
  const data = useRef<any>()
  const audioRef = useRef<any>()
  const { display_name, name, description, keywords } = UserStore.config

  useEffect(() => {
    initPage()
    document.addEventListener('visibilitychange', () => {
      if (display_name) {
        document.title = display_name
      }
    })
  }, [])

  const initPage = async () => {
    await UserStore.getSystemConfig()
    Api.getUserInfo()
    getData()
    setInterval(() => {
      getData()
    }, 10000)
  }

  const destroyAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause()
      audioRef.current.remove()
    }
  }

  const getData = async () => {
    if (location.href.includes('login') || location.href.includes('mobile')) return
    const result = await HomeApi.getCreations()
    if (data.current?.length) {
      const creatingIds = data.current.filter((d: any) => d.status === 1).map((d: any) => d.id)
      if ((result?.list || []).some((l: any) => l.status !== 1 && creatingIds.includes(l.id))) {
        if (document.visibilityState === 'hidden') {
          document.title = '作品已生成'
        }
        notification.destroy()
        notification.success({
          message: '作品已生成',
          description: <div>快去作品管理页查看吧！</div>,
          duration: null
        })
        destroyAudio()
        const audio = new Audio()
        audio.src = urlSource('hiflys/notice.mp3')
        audio.play()
        setTimeout(() => {
          audio.muted = true
        }, 2000)
        audioRef.current = audio
      }
    }
    data.current = result?.list || []
  }

  if (!name) return null

  return (
    <BrowserRouter>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#7559ff'
          }
        }}
      >
        <AuthRouter>
          <Suspense>
            <>
              <Router />
            </>
          </Suspense>
        </AuthRouter>
        <Helmet>
          <title>{display_name}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
        </Helmet>
      </ConfigProvider>
    </BrowserRouter>
  )
}
export default App
